import React from "react"
import { useSession } from "next-auth/react"
import { useRouter } from "next/router"
import AuthenticateModal from "../components/AuthenticateModal/AuthenticateModal"
import LoaderIcon from "../components/icons/LoaderIcon"

const LoginPage = () => {
  const router = useRouter()

  const { data: session, status } = useSession()

  React.useEffect(() => {
    if (router?.isReady) {
      if (status === "loading") {
        return
      }

      if (status === "authenticated" && session) {
        router.replace("/dashboard/home", undefined, {
          shallow: false,
          scroll: true,
        })
        return
      }
    }
  }, [session, status, router])

  if (status === "loading" || (session && status === "authenticated")) {
    return (
      <div
        style={{
          width: "100vw",
          height: "100vh",
          position: "relative",
          display: "grid",
          placeContent: "center",
        }}
      >
        <LoaderIcon style={{ width: 80, height: 80 }} />
      </div>
    )
  }

  return <AuthenticateModal />
}

export default LoginPage
