import styles from "./AuthenticateModal.module.scss"
import React from "react"
import cx from "clsx"

import { BrandCustom as BrandLogo } from "../Brand/Brand"

import Text from "../Text/Text"
import Link from "../Link/Link"
import Container from "../../layouts/Container/Container"
import LoginForm from "../LoginForm/LoginForm"

const AuthenticateModal = ({ children, className, ...restProps }) => {
  return (
    <main className={cx(styles.root)} {...restProps}>
      <div className={styles.badge}></div>
      <Container post className={styles.main}>
        <header className={styles.header}>
          <Link to="/" className={styles.logoWrapper}>
            <BrandLogo className={styles.logo} />
          </Link>
          <Text heading2 as="h1" className={styles.title}>
            Login
          </Text>
        </header>
        <LoginForm />
      </Container>
    </main>
  )
}

export default AuthenticateModal
